import styled, { keyframes } from "styled-components"

import PatternImageRight from "images/pattern-2.svg"
import UnderlineIcon from "images/underline.svg"

const svgAnimation = keyframes`
  0% {
    background-size: 0;
  }
  100% {
    background-size: 100%;
  }
`

export const CaseIntro = styled.section`
  padding: calc(90px + 8vh) 0 0 0;
  text-align: center;
  display: flex;
  flex-direction: column;
`

export const CaseContainer = styled.div`
  padding: 0 14px;
  margin: 0 auto;
  max-width: 1000px;
`

export const ImageWrapper = styled.div`
  margin: 0 auto 50px;
  margin-bottom: -100px;
  /* padding: 50px; */
  border-radius: 20px;

  @media (max-width: 1024px) {
    width: 90%;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: -50px;
  }
`

export const ImageInner = styled.div`
  border-radius: 20px;
  overflow: hidden;
  /* box-shadow: -0.3px -0.8px 12px rgba(0, 0, 0, 0.056),
    0.7px 1.9px 30.4px rgba(0, 0, 0, 0.08), 4px 10.5px 62px rgba(0, 0, 0, 0.1),
    12.1px 31.9px 127.8px rgba(0, 0, 0, 0.124),
    38px 100px 350px rgba(0, 0, 0, 0.18); */
`

export const CaseIntroTitle = styled.h1`
  font-weight: 400;
  margin: 0 0 20px 0;
  font-size: 22px;
  color: ${props => props.fontColor};

  @media (max-width: 768px) {
    font-size: 20px;
  }
`

export const CaseIntroDesc = styled.h2`
  display: inline-block;
  font-weight: 400;
  margin: 0 0 100px 0;
  font-size: 50px;
  max-width: 85%;
  color: ${props => props.fontColor};

  @media (max-width: 1024px) {
    font-size: 38px;
    max-width: 100%;
    margin: 0 0 60px 0;
    line-height: 1.3;
  }

  @media (max-width: 768px) {
    font-size: 28px;
    margin: 0 0 40px 0;
  }

  @media (max-width: 425px) {
    font-size: 24px;
  }
`

export const RichTextWrapper = styled.div`
  padding: 70px 0 8vh 0;

  @media (max-width: 768px) {
    padding: 100px 0 8vh 0;
  }

  @media (max-width: 425px) {
    padding: 70px 0 8vh 0;

    h1 {
      font-size: 28px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: block;
    font-weight: 700;
    line-height: 1.3;
  }

  h1,
  h2,
  h3 {
    margin: 48px 0 1rem 0;
  }

  h4,
  h5,
  h6 {
    margin: 0.8rem 0 0.8rem 0;
  }

  h2 {
    font-size: 32px;
  }

  p {
    margin: 0.8rem 0 0.8rem 0;
    font-size: 18px;
  }

  b {
    font-weight: 700;
  }

  ul {
    list-style: disc;
    margin-left: 1.5rem;
  }

  u {
    position: relative;
    text-decoration: none;
  }

  u::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    width: 100%;
    height: 14px;
    background-image: url(${UnderlineIcon});
    background-repeat: no-repeat;
    background-size: 0;
    transition: background-size 1s ease-in-out;
    animation: ${svgAnimation} 1s linear;
  }

  u.visible::after {
    background-size: 100%; // Make the underline visible
  }

  u:not(.visible)::after {
    background-size: 0; // Remove the underline when not visible
  }

  img {
    display: block;
    max-width: 100%;
    width: 100%;
  }
  /* div with attr data-gatsby-image-wrapper */
  div[data-gatsby-image-wrapper] {
    margin: 30px auto;
    border-radius: 8px;
  }
`
export const ProjectInfoField = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 2rem;
  row-gap: 1.5rem;
  margin-top: 150px;
  margin-bottom: 24px;

  @media screen and (min-width: 1024px) {
    column-gap: 7rem;
    row-gap: 2.5rem;
  }

  @media screen and (min-width: 768px) {
    column-gap: 2rem;
    row-gap: 2rem;
  }
`

export const ProjectInfoItemTitle = styled.p`
  font-weight: 600;
  font-size: 14px;
`

export const ProjectInfoItemValue = styled.p`
  font-size: 14px;
  position: relative;
`

export const ProjectInfoItemValueLink = styled.a`
  text-decoration: none;
  font-size: 14px;
  position: relative;

  &:hover {
    text-decoration: underline;
  }
`

export const ProjectInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
`

export const ScopeOfWorkField = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 0.5rem;
`

export const ScopeOfWorkItem = styled.p`
  font-size: 14px;
  padding: 0 8px;
  border-radius: 20px;
  border: 1px solid #24356e;
`

export const ProjectScopeRow = styled.div`
  margin-top: 48px;
`

export const TestimonialSection = styled.div`
  padding: 90px 0 90px 0;
  background-color: #11100f;
  position: relative;
`

export const CaseBirdRight = styled.div`
  height: auto;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0px;
  bottom: 0px;
  background-image: url(${PatternImageRight});
  background-repeat: no-repeat;
  background-position: bottom 30% right 5%;
  background-size: 590px;
  z-index: 0;
`
