import React, { useEffect, useRef } from "react"
import { BLOCKS } from "@contentful/rich-text-types"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import Cases from "../../components/Cases"
import TestimonialsSlideItem from "../../components/testimonialsNew/components/TestimonialsSlideItem"

import { Container } from "components/Layout/styled"
import {
  CaseBirdRight,
  CaseContainer,
  CaseIntro,
  CaseIntroDesc,
  CaseIntroTitle,
  ImageInner,
  ImageWrapper,
  ProjectInfoField,
  ProjectInfoItem,
  ProjectInfoItemTitle,
  ProjectInfoItemValue,
  ProjectScopeRow,
  RichTextWrapper,
  ScopeOfWorkField,
  ScopeOfWorkItem,
  TestimonialSection,
} from "./styled"

const SingleCase = ({ singleCase, cases }) => {
  const {
    backgroundColor,
    backgroundColorSecondary,
    fontColor,
    title,
    description,
    img,
    richText,
    projectInfo,
    id,
    testimonial,
  } = singleCase

  const observer = useRef(null)

  const handleIntersection = entries => {
    entries.forEach(entry => {
      const target = entry.target

      if (entry.isIntersecting) {
        target.classList.add("visible")
      } else {
        target.classList.remove("visible")
      }
    })
  }

  useEffect(() => {
    observer.current = new IntersectionObserver(handleIntersection, {
      threshold: 0.5,
    })

    document.querySelectorAll("u").forEach(element => {
      observer.current.observe(element)
    })

    return () => {
      if (observer.current) {
        observer.current.disconnect()
      }
    }
  }, [])

  const { technologies, ...restInfo } = projectInfo

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const { gatsbyImageData, title } = node.data.target

        return <GatsbyImage image={getImage(gatsbyImageData)} alt={title} />
      },
    },
  }

  return (
    <>
      <CaseIntro
        style={{
          background: backgroundColorSecondary
            ? `linear-gradient(to right, ${backgroundColor}, ${backgroundColorSecondary})`
            : backgroundColor,
        }}
      >
        <Container>
          <CaseIntroTitle style={{ color: fontColor }}>{title}</CaseIntroTitle>

          <CaseIntroDesc style={{ color: fontColor }}>
            {description}
          </CaseIntroDesc>

          <ImageWrapper>
            <ImageInner>
              <GatsbyImage image={getImage(img)} alt={title} />
            </ImageInner>
          </ImageWrapper>
        </Container>
      </CaseIntro>

      <Container>
        <ProjectInfoField>
          {Object.keys(restInfo).map((key, index) => {
            const title = key
              .split(/(?=[A-Z])/)
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")

            return (
              <ProjectInfoItem key={index}>
                <ProjectInfoItemTitle>{title}</ProjectInfoItemTitle>

                <ProjectInfoItemValue>
                  {key === "website" ? (
                    <Link
                      href={restInfo[key]}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {restInfo[key]}
                    </Link>
                  ) : (
                    restInfo[key]
                  )}
                </ProjectInfoItemValue>
              </ProjectInfoItem>
            )
          })}
        </ProjectInfoField>

        <ProjectScopeRow>
          <ProjectInfoItemTitle>Scope of Work</ProjectInfoItemTitle>

          <ScopeOfWorkField>
            {technologies.map((technology, index) => (
              <ScopeOfWorkItem key={index}>{technology}</ScopeOfWorkItem>
            ))}
          </ScopeOfWorkField>
        </ProjectScopeRow>
      </Container>

      <CaseContainer>
        <RichTextWrapper>{renderRichText(richText, options)}</RichTextWrapper>
      </CaseContainer>

      {testimonial && (
        <TestimonialSection>
          <CaseContainer>
            <TestimonialsSlideItem {...testimonial} isSingle />
          </CaseContainer>
          <CaseBirdRight />
        </TestimonialSection>
      )}

      <Cases cases={cases} backgroundColor={backgroundColor} caseId={id} />
    </>
  )
}

export default SingleCase
