import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"

import SingleCaseTemplate from "../containers/SingleCase"

import { Seo } from "components"

const SingleCase = ({ data }) => {
  const { singleCase, cases } = data

  return <SingleCaseTemplate singleCase={singleCase} cases={cases} />
}

export const query = graphql`
  query ($slug: String!) {
    singleCase: contentfulSingleCasePage(slug: { eq: $slug }) {
      title
      description
      backgroundColor
      backgroundColorSecondary
      id
      fontColor
      imageBackground
      cases {
        casesList {
          id
        }
      }
      img {
        gatsbyImageData
      }
      projectInfo {
        client
        location
        website
        projectStatus
        technologies
      }
      richText {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            title
            gatsbyImageData
            __typename
          }
        }
      }
      testimonial {
        name
        link
        linkText
        reviewTitle
        reviewerInfo
        reviewerName
        reviewText {
          reviewText
        }
      }
    }
    cases: contentfulCases {
      casesList {
        img {
          gatsbyImageData
        }
        description
        id
        projectInfo {
          projectStatus
          technologies
        }
        title
        slug
      }
      title
    }
  }
`

export const Head = ({ data: { singleCase }, location }) => {
  const seoImage = getImage(singleCase.img)

  return (
    <>
      <Seo
        title={singleCase.title}
        description={singleCase.description}
        ogUrl={location.pathname}
        imageUrl={seoImage.images.fallback.src}
      />
    </>
  )
}

export default SingleCase
